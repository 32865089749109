$search-expandable-background-color: white;

.search-expandable {
  display: flex;
  position: relative;
  align-items: center;
  overflow: hidden;
  background-color: $search-expandable-background-color;
  box-shadow: 0px 0px 2px black;

  .search-expandable-icon-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  form {
    position: absolute;

    input[type="search"] {
      outline: none;
      border: none;
      box-sizing: border-box;
      background-color: $search-expandable-background-color;

      &::-webkit-search-decoration {
        &:hover {
          cursor: pointer;
        }
      }

      &::-webkit-search-cancel-button {
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}
