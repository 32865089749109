.search-filters {
    display: flex;
    width: 70%;
    justify-content: flex-start;
}

.general-style {
    background-color: white;
    border: black solid 1px;
    border-radius: 3px;
    padding: 5px 10px;
}

.general-style-v2 {
    color: white;
    background-color: grey;
    border: white solid 1px;
    border-radius: 3px;
    padding: 5px 10px;

    span {
        display: none;
    }
}

.general-style-v2::before {
    content: "- "
}

.row1 {
    margin-right: 10px;
    margin-bottom: 5px;
}

.dropdown-content {
    margin: 0px 20px;
}

.dropdown-item {
    margin-right: 5px;
}

.chosen-filter-button:hover {
    background-color: grey;
    color: white;
    border: white solid 1px;

    span {
        display: none;
    }
}

.chosen-filter-button:hover::before {
    content: "- ";
}

.sort-option {
    display: inline;
    font-size: small;

    .general-style {
        border: none;
    }
}

.sort-option::before {
    content: "Sort by  "
}

.select-sort-option {
    background-color: white;
    border: none;
    text-align: center;
    padding: 3px 0px;
}

.select-currency {
    text-align: center;
    background-color: white;
    border: none;
}

.currency {
    margin-right: 0px;
    
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border-right: none;
}

.min-max {
    background-color: white;
    border: black solid 1px;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
}

.min-max-input {
    text-align: center;
    color: black;
    border: none;
    outline: none;
    width: 100px;
    padding: 8px 0px;
}

.to {
    font-size: small;
}

.dropdown-button {
    background-color: white;
    border: none;
}