@import "./variables.scss";
@import "./mixins.scss";

@import "../app.scss";

@import "../view/modal/modal.scss";

@import "../view/components/Buttons/buttons.scss";
// @import "../view/components/NavBar/navbar.scss";
@import "../view/components/NavBarV2/navbar.scss";
@import "../view/components/Footer/footer.scss";
@import "../view/components/Cards/cards.scss";
@import "../view/components/Dropdown/dropdown.scss";
@import "../view/components/Searches/searches.scss";
@import "../view/components/Paypal/paypal.scss";
@import "../view/components/Slideshows/slideshows.scss";
@import "../view/components/SearchFilter/searchfilter.scss";
@import "../view/components/Separator/separator.scss";
@import "../view/components/Inputs/inputs.scss";

@import "../view/pages/Home/home.scss";
@import "../view/pages/Product/product.scss";
@import "../view/pages/Cart/cart.scss";
@import "../view/pages/CheckOut/checkOut.scss";
@import "../view/pages/Account/account.scss";

.table-cell-center {
  display: table-cell;
  height: 100%;
  vertical-align: middle;
  text-align: center;
}

.fade-in {
  animation: fadein 0.3s;
  opacity: 1;

  @keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
}

@media (max-width: 1717px) {
  .tmp-background-img {
    display: none;
  }

  .tmp-background-img-right {
    width: 95%;
  }
}

@media (min-width: 1717px) {
  .tmp-background-img-right {
    width: 48%;
  }
}
