.cards-featured-merch {
  height: 500px;
  width: 320px;
  background-color: gray;
  overflow: hidden;

  .cards-featured-merch-image-container {
    width: 100%;
    height: 52%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .cards-featured-merch-details {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 48%;
    padding: 5px 12px 15px 12px;
    box-sizing: border-box;

    h2 {
      @include dotted-overflow-text(24px, 1, 1.8);
      margin: 10px auto 0px auto;
      text-align: center;
    }

    .cards-featured-merch-description {
      @include dotted-overflow-text(15px, 5, 1.8);
      text-align: center;
      font-style: italic;
      color: $theme-color-darker;
      padding: 0 5px 0 5px;
    }

    .cards-featured-merch-price {
      text-align: center;
      font-size: 24px;

      .cards-featured-merch-price-converted-currency {
        font-size: 15px;
      }
    }
  }
}
