.home-featured-merchs-simple {
  width: 95vw;
  max-width: $standard-max-width;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  @media screen and (min-width: $max-header-width) {
    display: none;
  }

  @media screen and (min-width: $tablet-limit) {
    margin-top: -280px;
  }

  .home-featured-merchs-simple-card {
    width: 32%;
    height: 30vw;
    max-height: #{$standard-max-width * 0.3};

    z-index: $z-index-highest;
    background-color: $theme-color-bright;
    margin: 10px 0;
    cursor: pointer;
    background-color: white;

    .home-featured-merchs-simple-title {
      height: 20%;
      position: relative;

      .home-featured-merchs-simple-title-container {
        margin: auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;

        h2 {
          @media screen and (min-width: $standard-max-width) {
            @include dotted-overflow-text(
              #{$standard-max-width * 0.035},
              1,
              100%
            );
          }

          @media screen and (max-width: $standard-max-width) {
            @include dotted-overflow-text(3.5vw, 1, 100%);
          }

          padding: 0;
          text-align: center;
        }
      }
    }

    .home-featured-merchs-simple-card-image-container {
      height: 70%;
      width: 90%;
      margin: auto;

      overflow: hidden;

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
  }

  .home-featured-merchs-simple-card.home-featured-merchs-simple-card-block-2 {
    width: 49%;
  }

  .home-featured-merchs-simple-card.home-featured-merchs-simple-card-block-1 {
    width: 100%;
  }
}
