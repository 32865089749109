$wavy-slideshow-height: 580px;
$wavy-slidershow-bottom-space: 60px;
$wavy-slideshow-color: #22222a;

$wavy-slideshow-image-height: 500px;
$wavy-slideshow-image-width: 500px;
$wavy-slideshow-image-top: 80px;

$wavy-slideshow-transition-duration: 0.2s;

.wavy-slideshow {
  margin-bottom: 100px;
}

.wavy-slideshow-container {
  background-color: $wavy-slideshow-color;
  margin-bottom: $wavy-slidershow-bottom-space;
  user-select: none;
  // margin-bottom: 20px;

  @media screen and (max-width: $max-header-width) {
    display: none;
  }

  // &:hover {
  //   // border: solid 5px red;

  //   .wavy-slideshow-content-width {
  //     .wavy-slideshow-slideshow {
  //       .wavy-slideshow-slideshow-items {
  //         // border: solid 5px red;

  //         .wavy-slideshow-active {
  //           .wavy-slideshow-item-image-container {
  //             .wavy-slideshow-item-image {
  //               border: solid 5px red;

  //               opacity: 0.9;
  //             }
  //           }
  //         }
  //       }
  //     }
  //   }
  // }
}

.wavy-slideshow-content-width {
  width: 90%;
  height: calc($wavy-slideshow-height - $wavy-slidershow-bottom-space);
  margin: auto;
  position: relative;
  // transition: 0.2s;

  &::before {
    content: "";
    position: absolute;
    width: $wavy-slideshow-image-width;
    height: $wavy-slideshow-image-height;
    z-index: $z-index-fourth-highest;
    background: $wavy-slideshow-color;
    top: calc($wavy-slideshow-height - $wavy-slideshow-image-height);
    transition: $wavy-slideshow-transition-duration;
  }
}

.wavy-slideshow-slideshow {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.wavy-slideshow-slideshow-items {
  position: relative;
  width: 100%;
  height: 300px;
}

.wavy-slideshow-item {
  position: absolute;
  width: 100%;
  height: auto;
}

.wavy-slideshow-item-image-container {
  position: absolute;
  z-index: $z-index-third-highest;
  width: $wavy-slideshow-image-width;
  height: $wavy-slideshow-image-height;
  top: calc($wavy-slideshow-height - $wavy-slideshow-image-height);

  .wavy-slideshow-item-image {
    opacity: 0;
    transition: opacity 0.3s ease-out 0.45s;
    width: $wavy-slideshow-image-width;
    height: $wavy-slideshow-image-height;
    object-fit: cover;
    transition: $wavy-slideshow-transition-duration;
  }
}

.wavy-slideshow-item.wavy-slideshow-active .wavy-slideshow-item-image {
  opacity: 0.5;
}

.wavy-slideshow-item.wavy-slideshow-active
  .wavy-slideshow-item-image-container {
  z-index: $z-index-third-highest;
}

.wavy-slideshow-item-description {
  position: absolute;
  top: 182px;
  right: 0;
  width: 50%;
  padding-right: 4%;
  line-height: 1.8;
}

.wavy-slideshow-item-header {
  position: absolute;
  top: 150px;
  left: -1.8%;
  z-index: $z-index-highest;
}

.wavy-slideshow-item-header .wavy-slideshow-vertical-part {
  margin: 0 -4px;
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: auto;
  color: #fff;
  font-size: 5em;
}

.wavy-slideshow-vertical-part {
  overflow: hidden;
  display: inline-block;
}

.wavy-slideshow-vertical-part b {
  display: inline-block;
  transform: translateY(100%);
}

.wavy-slideshow-item-header .wavy-slideshow-vertical-part b {
  transition: 0.5s;
}

.wavy-slideshow-item-description .wavy-slideshow-vertical-part b {
  transition: 0.21s;
}

.wavy-slideshow-item.wavy-slideshow-active
  .wavy-slideshow-item-header
  .wavy-slideshow-vertical-part
  b {
  transform: translateY(0);
}

.wavy-slideshow-item.wavy-slideshow-active
  .wavy-slideshow-item-description
  .wavy-slideshow-vertical-part
  b {
  transform: translateY(0);
}

.wavy-slideshow-controls {
  position: relative;
  text-align: right;
  z-index: $z-index-second-highest;
}

.wavy-slideshow-controls ul {
  list-style: none;
}

.wavy-slideshow-controls ul li {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 3px;
  background: #bdbdd5;
  cursor: pointer;
}

.wavy-slideshow-controls ul li.wavy-slideshow-active {
  background: #6a6a77;
}

.wavy-slideshow-container {
  cursor: pointer;

  &:hover {
    // .wavy-slideshow-item-image-container {
    //   transform: scale(1.1);
    // }

    .wavy-slideshow-content-width {
      // transform: scale(1.1);

      &::before {
        transform: scale(1.05);
      }
    }

    .wavy-slideshow-item-image {
      // border: solid 5px red;

      // opacity: 0.9;

      // scale: (1.2);
      transform: scale(1.05);
    }
  }
}
