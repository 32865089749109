$theme-color-dark: #02203c;
$theme-color-darker: #021a31;
$theme-color-dark-brighter: #033563;
$theme-color-bright: #f6f9fc;

$footer-height: 60px;

$standard-max-width: 1800px;

$z-index-modal: 100;
$z-index-highest: 10;
$z-index-second-highest: 9;
$z-index-third-highest: 8;
$z-index-fourth-highest: 7;

$transition-standard: 0.2s;

$desktop-limit: 1500px;
$tablet-limit: 800px;

$max-header-width: 2200px;
