@import "./Header/header.scss";
@import "./Merchandise/merchandise.scss";
@import "./FeaturedMerchandise/featuredMerchandise.scss";

.home-main {
  max-width: $standard-max-width;
  margin: auto;

  .home-main-padded {
    margin: 0 20px;
    padding-bottom: 70px;
  }
}
