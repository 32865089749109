$bg-color: #222;
$margin: 20px;

.slideshow-draggable {
  max-width: 1200px;
  margin: 0 auto;
  padding: 50px 0;

  h1 {
    margin-bottom: 50px;
  }

  .wrapper {
    position: relative;

    &:before,
    &:after {
      position: absolute;
      top: 0;
      z-index: 1;

      content: "";
      display: block;
      width: $margin;
      height: 100%;
    }

    &:before {
      left: 0;
      background: linear-gradient(90deg, $bg-color, transparent);
    }

    &:after {
      right: 0;
      background: linear-gradient(-90deg, $bg-color, transparent);
    }

    ul {
      padding: 0;

      li {
        list-style: none;
      }
    }

    .items {
      position: relative;
      width: 100%;
      overflow: hidden;
      white-space: nowrap;
      font-size: 0;
      cursor: pointer;

      &.active {
        cursor: grab;
      }

      .slideshow-draggable-item {
        display: inline-block;
        margin-left: $margin;
        user-select: none;

        background: tomato;
        width: 50%;
        height: 130px;
        color: $bg-color;
        font-size: 33px;
        font-weight: bold;
        line-height: 130px;

        &:last-child {
          margin-right: $margin;
        }
      }
    }
  }
}

@media screen and (min-width: 500px) {
  .slideshow-draggable-item {
    width: 33%;
  }
}

@media screen and (min-width: 800px) {
  .slideshow-draggable-item {
    width: 25%;
  }
}

@media screen and (min-width: 1200px) {
  .wrapper {
    margin-left: -$margin;
  }

  .slideshow-draggable-item {
    width: 20%;
  }
}
