@mixin dotted-overflow-text($font-size, $number-of-lines, $line-height) {
  display: block;
  display: -webkit-box;
  max-width: 100%;
  margin: 0 auto;
  font-size: $font-size;
  line-height: $line-height;
  -webkit-line-clamp: $number-of-lines;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin highlight-content-on-hover(
  $color,
  $height,
  $transition,
  $visible-without-hover: false
) {
  position: relative;

  &:before {
    content: "";
    position: absolute;
    width: 100%;
    height: $height;
    bottom: 0;
    left: 0;
    background-color: $color;

    transition: all $transition ease-in-out;

    @if $visible-without-hover {
      visibility: visible;
    } @else {
      visibility: hidden;
      transform: scaleX(0);
    }
  }

  @if not $visible-without-hover {
    &:hover::before {
      visibility: visible;
      transform: scaleX(1);
    }
  }
}

@mixin flex($fd: null, $ai: null, $jc: null, $gap: null) {
  display: flex;

  @if $fd != null {
      flex-direction: $fd;
  }
  @if $ai != null {
      align-items: $ai;
  }
  @if $jc != null {
      justify-content: $jc;
  }
  @if $gap != null {
      gap: $gap;
  }
}

@mixin medium {
  @media (max-width: 950px) {
      @content;
  }
}
@mixin mobile {
  @media (max-width: 450px) {
      @content;
  }
}
