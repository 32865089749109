@import url("https://fonts.googleapis.com/css?family=Roboto+Condensed:400,700");

// body{
//   background: url('http://all4desktop.com/data_images/original/4236532-background-images.jpg');
//   font-family: 'Roboto Condensed', sans-serif;
//   color: #262626;
//   margin: 5% 0;
// }
.checkout-container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;

  .d-flex {
    display: flex;
    flex-direction: row;
    //background: #f6f6f6;
    border-radius: 0 0 5px 5px;
    padding: 25px;
  }
  form {
    flex: 4;
  }
  .Yorder {
    flex: 2;
	display: none;
  }
  .title {
    background: -webkit-gradient(
      linear,
      left top,
      right bottom,
      color-stop(0, #5195a8),
      color-stop(100, #70eaff)
    );
    background: -moz-linear-gradient(top left, #5195a8 0%, #70eaff 100%);
    background: -ms-linear-gradient(top left, #5195a8 0%, #70eaff 100%);
    background: -o-linear-gradient(top left, #5195a8 0%, #70eaff 100%);
    background: linear-gradient(to bottom right, #5195a8 0%, #70eaff 100%);
    border-radius: 5px 5px 0 0;
    padding: 20px;
    color: #f6f6f6;
  }
  h2 {
    margin: 0;
    padding-left: 15px;
  }
  .required {
    color: red;
  }
  label,
  table {
    display: block;
    margin: 15px;
  }
  label > span {
    /*float: left;
    width: 25%;
    margin-top: 12px;
    padding-right: 10px;*/
	
	margin-bottom: -30px;
    z-index: 1;
    position: relative;
    padding: 0px 10px 0 20px;
    pointer-events: none;
    font-size: 80%;
    font-weight: 400;
	display: block;
  }
  input[type="text"],
  input[type="tel"],
  input[type="email"],
  select {
    /*width: 70%;
    height: 30px;
    padding: 5px 10px;
    margin-bottom: 10px;
    border: 1px solid #dadada;
    color: #888;*/
	
	border: 0;
    border-radius: 6px;
    padding: 35px 20px 15px;
    margin: 0 0 20px;
    width: 100%;
    box-shadow: inset 0 0 0 1px #ccc;
  }
  select {
    /*width: 72%;
    height: 45px;
    padding: 5px 10px;
    margin-bottom: 10px;*/
	
	border: 0;
    border-radius: 6px;
    padding: 35px 20px 15px;
    margin: 0 0 20px;
    width: 100%;
    box-shadow: inset 0 0 0 1px #ccc;
  }
  .Yorder {
    margin-top: 15px;
    height: 600px;
    padding: 20px;
    border: 1px solid #dadada;
  }
  table {
    margin: 0;
    padding: 0;
  }
  th {
    border-bottom: 1px solid #dadada;
    padding: 10px 0;
  }
  tr > td:nth-child(1) {
    text-align: left;
    color: #2d2d2a;
  }
  tr > td:nth-child(2) {
    text-align: right;
    color: #52ad9c;
  }
  td {
    border-bottom: 1px solid #dadada;
    padding: 25px 25px 25px 0;
  }

  p {
    display: block;
    color: #888;
    margin: 0;
    padding-left: 25px;
  }
  .Yorder > div {
    padding: 15px 0;
  }

  button {
    width: 100%;
    margin-top: 10px;
    padding: 10px;
    border: none;
    border-radius: 30px;
    background: #52ad9c;
    color: #fff;
    font-size: 15px;
    font-weight: bold;
  }
  button:hover {
    cursor: pointer;
    background: #428a7d;
  }

  .paypal-buttons-container {
    z-index: 1000;
  }
  
  .mark-address {
	display: inline-block;
    padding: 0;
  }
  
  .checkout-header:before, checkout-header:after {
	content: "";
	display: table;
  }
	
  .checkout-header {
		border-bottom: 1px solid;
		margin-bottom: 30px;
		
		h2 {
		}
		
		label {
			margin: 0;
			padding-left: 15px;
		}
		
		span {
		}
		
		.required-notice {
			padding-left: 15px;
		}
  }
}
@media (min-width: 1200px) {
  .checkout-container {
    max-width: 1140px;
  }
	
  
  .checkout-header {
		position: relative;
		padding-bottom: 10px;
		margin-bottom: 45px;
		
		h2 {
			display: inline-block;
		}
		
		label {
			display: inline-block;
			margin: 0;
			margin-left: 50px;
		}
		
		span {
		}
		
		.required-notice {
			position: absolute;
			right: 0;
			bottom: 10px;
		}
  }
	
	
	.form-group {
		display: flex;
    justify-content: space-between;
		
		label {
			width: 48%;
			margin-bottom: 0px;
		}
	}
}
