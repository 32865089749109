.dropdown {
  $dropdown-item-height: 30px;
  position: relative;
  user-select: none;

  .dropdown-bordered {
    border: gray 1px solid;
  }

  .dropdown-common {
    height: $dropdown-item-height;
    display: flex;
    align-items: center;
    background-color: white;
    color: black;

    &:hover {
      cursor: pointer;
    }

    label {
      font-size: 1em;
      cursor: inherit;
    }
  }

  .dropdown-selection {
    @extend .dropdown-bordered;
    @extend .dropdown-common;
    white-space: nowrap;
    overflow: hidden;

    label {
      width: 100%;
    }
  }

  .dropdown-menu {
    @extend .dropdown-bordered;
    position: absolute;
    background-color: white;

    .dropdown-item {
      @extend .dropdown-common;
      transition: 0.3s;
      border: none;
      outline: 0;
      width: 100%;
      white-space: nowrap;
      border-radius: 0;

      &:hover {
        background-color: #d9d9d9;
      }
    }
  }
}
