.button-submit {
  border: none;
  background-color: #2a838d;
  border-radius: 3px;
  margin: 0 auto;
  padding: 5px 0;
  transition: 0.2s;
  user-select: none;
  outline-width: 0;
  color: $theme-color-bright;

  &:hover {
    cursor: pointer;
    transform: scale(1.01);
    transition: 0.2s;
    box-shadow: 3px 3px black;
  }

  &:active {
    transform: translate(1px, 1px);
    box-shadow: 1px 1px black;
    transition: 0.1s;
  }
}

.button-standard-optional-icon {
  position: relative;
  height: 43px;
  color: #1e1e1e;
  text-align: center;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: 40px;
  border: solid 0.5px black;
  border-radius: 10px;
  vertical-align: middle;
  // margin-top: 2.6px;
  background-color: #51f5ff;
  cursor: pointer;

  &:hover {
    background-color: #44d3db;
  }

  .button-standard-optional-icon-icon {
    position: absolute;
    top: 2px;
    left: 5px;
  }
}

.ripple {
  position: relative;
  overflow: hidden;

  .ripple-container {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    @keyframes ripple {
      to {
        opacity: 0;
        transform: scale(2);
      }
    }

    span {
      transform: scale(0);
      border-radius: 100%;
      position: absolute;
      opacity: 0.75;
      background-color: #ffffff;
      animation: ripple 650ms;
    }
  }
}
