.input-inner-label {
  border: solid 1px black;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 7px 12px;
  height: 64px;

  &.input-inner-label-error {
    border: solid 2px red;
  }

  label {
    height: 18.86px;
    color: #000;
    font-family: Inter;
    font-size: 16.031px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .input-inner-label-input-container {
    height: 40px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .input-inner-label-show-password-icon {
      width: 20px;
      margin: 0 6px 0 6px;
      cursor: pointer;
    }

    input {
      height: 100%;
      width: 100%;
      border: hidden;
      outline: none;
      font-size: 20px;
    }
  }
}
