.separator {
  display: flex;
  align-items: center;
  text-align: center;

  &:before,
  &:after {
    content: "";
    flex: 1;
    border-bottom: 1px solid #000;
  }

  &:not(:empty)::before {
    margin-right: 0.25em;
  }

  &:not(:empty)::after {
    margin-left: 0.25em;
  }
}
