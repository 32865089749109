.modal-background {
  display: block;
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.6);
  -webkit-user-select: none;
  user-select: none;

  .modal {
    background-color: white;
    color: black;
    min-height: 200px;
    min-width: 200px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .modal-close {
      position: absolute;
      top: 43px;
      right: 20px;
      cursor: pointer;
      display: flex;
      flex-direction: row;
      color: #0f1111;
      font-family: Arial;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;

      span {
        margin-right: 5px;
      }

      .modal-close-icon {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }

    .modal-announcement {
      padding: 30px 30px;
      min-width: 300px;
    }

    .modal-settings {
      padding: 30px 30px;
      width: 300px;

      .modal-settings-currency-select {
        margin-top: 10px;
      }
    }

    .modal-login {
      margin: 109px 106px 92px 106px;
      width: 413px;
      display: flex;
      flex-direction: column;

      #modal-login-header {
        color: #000;
        text-align: center;
        font-family: Open Sans;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      #modal-login-form {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-top: 40px;

        .modal-input-field {
          margin: 2.6px 0px;
        }

        .modal-login-incorrect-msg {
          margin-top: 3px;
          display: flex;
          flex-direction: row;
          justify-content: center;

          span {
            background-color: #ffb2b2;
            border: solid 1px #f00;
            padding: 0 5px 0 5px;
          }
        }

        .modal-login-button-general {
          margin-top: 2.6px;
        }
      }

      #modal-login-forget-password {
        margin-top: 5px;
        color: #000;
        text-align: center;
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        text-decoration-line: underline;
        cursor: pointer;
      }
    }

    .modal-login-separator-1 {
      color: #000;
      text-align: center;
      font-family: Inter;
      font-size: 18px;
      font-style: normal;
      font-weight: 300;
      line-height: normal;

      margin-top: 30px;
      margin-bottom: 26px;
    }
  }
}
