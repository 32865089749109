.previewer {
  --sizeIcon: 1.25rem;
  --sizeIconBig: 2.5rem;
  --sizePreviewer: 500px;
  --sizePreviewerPlaylist: 400px;

  max-width: var(--sizePreviewer);
  @include flex($fd: column, $gap: var(--gap1));

  &Main {
      position: relative;

      img {
          border-radius: 0.5rem;
      }
  }

  &Btn {
      width: calc(var(--sizeIcon) + 1.5rem);
      height: calc(var(--sizeIcon) + 1.5rem);
      position: absolute;
      top: 50%;
      display: none;
      align-items: center;
      justify-content: center;
      background-color: var(--white);
      border: none;
      outline: none;
      border-radius: 50%;

      img {
          width: var(--sizeIcon);
          height: var(--sizeIcon);
          transition: transform 0.2s ease;
      }

      &:active {
          img {
              transform: scale(1.25);
          }
      }

      &:first-child {
          left: 0;
          transform: translate(-50%, -50%);
      }
      &:last-child {
          right: 0;
          transform: translate(50%, -50%);
      }
  }

  &Img {
    width: var(--sizePreviewer);
  }

  &Playlist {
      margin: 0 auto;
      max-width: var(--sizePreviewerPlaylist);
      display: flex;
      justify-content: center;
      gap: 1rem;
  }

  &Item {
      width: 100%;
      aspect-ratio: 1 / 1;
      position: relative;
      border: 2px solid transparent;
      border-radius: 0.5rem;
      overflow: hidden;
      cursor: pointer;
      transition: border-color 0.2s ease;

      img {
        width: 100%;
      }

      &::after {
          content: "";
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          background-color: var(--white);
          opacity: 0;
          transition: opacity 0.2s ease;
      }

      &Selected {
          border-color: var(--orange);

          &::after {
              opacity: 0.6 !important;
          }
      }

      &:hover {
          &::after {
              opacity: 0.3;
          }
      }
  }

  @include mobile() {
      &Home {
          --sizePreviewer: 100%;

          .previewer {
              &Main {
                  img {
                      border-radius: 0;
                  }
              }

              &Btn {
                  display: flex;

                  &:first-child {
                      transform: translate(50%, -50%);
                  }
                  &:last-child {
                      transform: translate(-50%, -50%);
                  }
              }

              &Playlist {
                  display: none;
              }
          }
      }
  }
}