.product-card {
  border: 1px solid #ccc;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  margin: 15px 10px;
  padding: 16px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  max-width: 300px;
}

.image-slider {
  position: relative;
  max-width: 100%;
}

.slider-image {
  max-width: 100%;
  height: auto; 
}

.pagination-dots {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.dot {
  width: 8px;
  height: 8px;
  background-color: #ccc;
  border-radius: 50%;
  margin: 0 4px;
  cursor: pointer;
}

.dot.active {
  background-color: #333;
}

.prev-button,
.next-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: #333;
  color: white;
  padding: 6px 10px;
  border: none;
  cursor: pointer;
  border-radius: 50%;
}

.prev-button {
  left: 10px;
}

.next-button {
  right: 10px;
}

.product-price {
  margin-top: 8px;
  font-weight: bold;
}

.product-description {
  font-size: 14px;
  font-weight: bold;
}

.quick-view-text {
  background-color: #1F78FF;
  color: white; 
  margin: 12px 0px;
  cursor: pointer;
  border-radius: 15px;
  padding: 5px 8px;
}

.quick-view {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.quick-view-content {
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 300px;
  text-align: center;
  max-width: 80%;
  max-height: 80%;
  overflow-y: auto; 
}