.home-merchs {
  .home-merchs-display {
    .home-merchs-display-tab-panel {
      $home-merchs-display-tab-item-space: 10px;

      border-bottom: 2px solid lightgray;
      padding: 10px 0;
      font-size: 20px;
      font-weight: bold;
      color: $theme-color-dark-brighter;

      .home-merchs-display-tab-items-wrapper {
        display: flex;
        flex-direction: row;
        margin: 0 -$home-merchs-display-tab-item-space;

        .home-merchs-display-tab-item {
          margin: 0 $home-merchs-display-tab-item-space;
          cursor: pointer;
          user-select: none;

          &.home-merchs-display-tab-item-active {
            @include highlight-content-on-hover(
              $theme-color-dark-brighter,
              3px,
              $transition-standard,
              true
            );
          }

          &.home-merchs-display-tab-item-inactive {
            @include highlight-content-on-hover(
              $theme-color-dark-brighter,
              1px,
              $transition-standard,
              false
            );
          }
        }
      }
    }
  }

  .home-merchs-group {
    $home-merchs-group-background-color: white;

    .home-merch-headline-wrapper {
      display: flex;
      align-items: center;

      &.home-merch-headline-group {
        border-bottom: 5px solid $theme-color-dark-brighter;
      }

      &.home-merch-headline-subgroup {
        border-bottom: 2px solid $theme-color-dark-brighter;
      }

      h2 {
        color: $theme-color-dark-brighter;
        margin: 12px 10px 12px 0;
      }

      .home-merch-group-see-more {
        color: #007185;
        cursor: pointer;
      }

      .home-merch-group-search {
        margin: 10px;
      }
    }

    .home-merch-cards-container {
      @extend .scrollbar-themed-rounded;

      overflow: auto;
      white-space: nowrap;
      background-color: $home-merchs-group-background-color;

      .home-merch-card-rounded {
        $home-merch-card-rounded-size: 250px;
        $home-merch-card-rounded-label-height: 55px;

        height: $home-merch-card-rounded-size;
        width: $home-merch-card-rounded-size;
        display: inline-block;
        margin: 10px 10px;
        cursor: pointer;
        position: relative;
        transition: $transition-standard;

        &:hover {
          transform: scale(1.05);
          transition: $transition-standard;
        }

        .home-merch-card-rounded-img-wrapper {
          height: 100%;
          width: 100%;
          border-radius: $home-merch-card-rounded-size;
          overflow: hidden;

          img {
            width: 100%;
            height: calc(
              #{$home-merch-card-rounded-size} - #{$home-merch-card-rounded-label-height}
            );
            object-fit: cover;
          }
        }

        h2 {
          @include dotted-overflow-text(24px, 1, 0.5);
          position: absolute;
          margin-bottom: 0;
          height: $home-merch-card-rounded-label-height;
          line-height: $home-merch-card-rounded-label-height;
          bottom: 0;
          left: 0;
          right: 0;
          text-align: center;
          background-color: $home-merchs-group-background-color;
        }
      }

      .home-merch-card-standard {
        $home-merch-card-thumbnail-height-proportion: 52%;
        height: 380px;
        width: 250px;
        display: inline-block;
        margin: 10px 10px;
        user-select: none;
        cursor: pointer;
        transition: $transition-standard;

        @media screen and (min-width: $desktop-limit) {
          &:hover {
            box-shadow: 0px 0px 10px black;
            z-index: $z-index-highest;
            transition: $transition-standard;

            .home-merch-card-standard-image-container {
              img {
                width: 110%;
                height: 110%;
                margin-top: -5%;
                margin-left: -5%;
              }
            }
          }
        }

        &:hover {
          .home-merch-card-standard-image-container {
            img {
              transform: scale(1.1);
            }
          }
        }

        .home-merch-card-standard-image-container {
          width: 100%;
          height: $home-merch-card-thumbnail-height-proportion;
          overflow: hidden;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            transition: 0.1s;
          }
        }

        .home-merch-card-standard-details {
          overflow: hidden;
          height: calc(100% - #{$home-merch-card-thumbnail-height-proportion});
          padding: 0 10px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          background-color: $theme-color-bright;

          h4 {
            @include dotted-overflow-text(24px, 1, 1.8);
            margin: 10px auto 0px auto;
            text-align: center;
            margin: 0 0;
          }

          p {
            @include dotted-overflow-text(14px, 4, 1.8);
            text-align: left;
            white-space: normal;
            padding: 0;
          }

          .home-merch-card-standard-price {
            margin: 9px auto;
            font-size: 20px;
          }
        }
      }
    }
  }
}
