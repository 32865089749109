#account {
  display: flex;
  justify-content: center;
  color: black;
  font-style: normal;
  line-height: normal;

  #account-container {
    width: 1280px;

    #account-header {
      height: 51px;
      text-align: center;
      vertical-align: middle;
      line-height: 51px;
      font-family: Arial;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      margin-top: 4px;
      margin-bottom: 20px;
    }

    #account-content {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .account-side-title {
        display: flex;
        width: 278px;
        height: 24px;
        flex-direction: column;
        justify-content: center;
        flex-shrink: 0;
        font-size: 16px;
        font-weight: 400;
      }

      #account-menu {
        width: 31%;
        -webkit-user-select: none;
        user-select: none;

        #account-menu-index {
          width: 371px;
          height: 205px;
          flex-shrink: 0;
          border: solid 1px black;
          padding: 18px 16px 15.68px 19px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          .account-menu-item {
            font-size: 15px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
          }

          .account-menu-item-selected {
            font-weight: 800;
            cursor: default;
          }

          .account-menu-item-unselected {
            font-weight: 400;
            cursor: pointer;
          }
        }
      }

      #account-content-right {
        width: 68%;
      }
      #account-details {
        width: 100%;

        #account-summary {
          //
        }

        #account-history {
          //
        }

        #account-address {
          //
        }

        #account-password {
          //
        }

        #account-logout {
          //
        }
      }
    }
  }
}
