.footer {
  background-color: $theme-color-dark;
  color: $theme-color-bright;
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  user-select: none;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: $footer-height;
  line-height: $footer-height;
}
